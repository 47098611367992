import API, { getSSO } from '../http-helper.service';

export default class FormFillerService {
  constructor() {
    const accessToken = localStorage.getItem('access_token') ?
      localStorage.getItem('access_token') : '';
    const userSSO = localStorage.getItem('ge_sso') ?
      localStorage.getItem('ge_sso') : '';
    this.state = {
      accessToken: accessToken,
      config: { headers: { 'Authorization': `Bearer ${accessToken}` } },
      userSSO: userSSO
    }
  }
  getAllSites() {
    return API.get('builder/assetMgmt/sites', this.state.config).then(res => {
      let sites = [];
      if (res.data.sites) {
        sites = res.data.sites.filter(function (site) {
          return site.lifeCycleStatus === 'Operational';
        });
      }
      return sites;
    }, error => {
      return error;
    });
  }

  getForm(formId) {
    let userId = getSSO();
    let authorization = this.state.config.headers.Authorization;
    let config = { headers: { Authorization: authorization, userId: userId } };
    return API.get('filler/formData/' + formId, config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  deleteForm(formId) {
    let userId = getSSO();
    let authorization = this.state.config.headers.Authorization;
    let config = { headers: { Authorization: authorization, userId: userId } };
    let baseUrl = 'filler/formData/delete-formdata?id=' + formId + '';
    let data = {};
    return API.put(baseUrl, data, config).then(res => {
      return res;
    }, err => {
      let error;
      error = (err.response) ? err.response : error;
      return error;
    });
  }

  approveForm(formObject) {
    let userId = getSSO();
    let authorization = this.state.config.headers.Authorization;
    let config = { headers: { Authorization: authorization, userId: userId } };
    let baseUrl = 'filler/formData/approve-or-reject';
    return API.put(baseUrl, formObject, config).then(res => {
      return res;
    }, err => {
      let error;
      error = (err.response) ? err.response : error;
      return error;
    });
  }

  rejectForm(formObject) {
    let userId = getSSO();
    let authorization = this.state.config.headers.Authorization;
    let config = { headers: { Authorization: authorization, userId: userId } };
    let baseUrl = 'filler/formData/approve-or-reject';
    return API.put(baseUrl, formObject, config).then(res => {
      return res;
    }, err => {
      let error;
      error = (err.response) ? err.response : error;
      return error;
    });
  }

  downloadForm(formId) {
    let authorization = this.state.config.headers.Authorization;
    let config = { headers: { Authorization: authorization, tenantId: 'ren' } };
    let baseUrl = 'filler/formData/' + formId + '/pdf';
    return API.get(baseUrl, { responseType: 'blob' }, config).then(res => {
      return res;
    },
      err => {
        let error
        error = (err.response) ? err.response : error;
        return error;
      }
    );
  }

  downloadSelectedForms(formIds) {
    let authorization = this.state.config.headers.Authorization;
    let config = { headers: { Authorization: authorization, tenantId: 'ren' } };
    let baseUrl = 'filler/formData/downloadZip?id=' + formIds;
    return API.get(baseUrl, { responseType: 'blob' }, config).then(res => {
      return res;
    },
      err => {
        let error
        error = (err.response) ? err.response : error;
        return error;
      }
    );
  }

  createUserGroup(groupData) {
    let baseURL = 'builder/groups';
    return API.post(baseURL, groupData, this.state.config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }

  saveForm(id, form) {
    let userId = getSSO();
    let authorization = this.state.config.headers.Authorization;
    let config = { headers: { Authorization: authorization, userId: userId } };
    let baseUrl = `filler/formData/${id}?isFormBuilderUpdate=true`
    return API.put(baseUrl, form, config).then(res => {
      return res;
    }, error => {
      return error;
    });
  }
}